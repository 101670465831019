import React, { useState } from 'react';
import { graphql } from 'gatsby';
// import SEO from "../components/seo"
import Layout from '../components/layout';
// import ReactMarkdown from 'react-markdown'

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/doctor-bios-hero.jpg';
import { Container, Row, Col } from 'react-grid-system';
import TwoCol from '../components/rows/twocol/index';
import Follow from '../components/rows/cta/follow';
import Cta from '../components/rows/cta/index';
// import Button from '../components/elements/button'

const TeamTemplate = ({ data }) => {
  let team = data.strapiTeams;

  // const isSpecialist =
  //   team.Category === 'NursePractitioner' || team.name === 'Edith Dell';

  // Get Bio Count
  const bio = team.bio;
  const bioCount = bio.split('\n\n').length;

  // Toggle Biography
  const [toggle, setToggle] = useState();

  return (
    <Layout>
      <Hero title="Team Member" team member={team.name} banner={Banner} />
      <Container
        className={`bio-chunk ${toggle === true ? 'show-bio' : 'hide-bio'}`}
      >
        <TwoCol
          key={team.id}
          team
          title={team.name}
          certification={team.certification}
          description={team.bio}
          image={
            team.picture.localFile.publicURL
              ? team.picture.localFile.publicURL
              : null
          }
        />
        {bioCount > 1 ? (
          <Row>
            <Col md={1}>&nbsp;</Col>
            <Col md={5} className="padding-30">
              <p
                className="cursor-pointer border border-red-500 text-red-500 hover:bg-red-500 hover:text-white inline-flex p-6 rounded-md duration-300 justify-center mr-5"
                onClick={() => setToggle(!toggle)}
              >
                Read {toggle === true ? 'less' : 'more'} about this{' '}
                {/* {isSpecialist ? 'specialist' : 'physician'} */}
                Specialist
              </p>
            </Col>
            <Col md={1}>&nbsp;</Col>
          </Row>
        ) : null}
      </Container>
      <Cta />
      <Follow />
    </Layout>
  );
};

export default TeamTemplate;

export const query = graphql`
  query TeamTemplate($slug: String!) {
    strapiTeams(slug: { eq: $slug }) {
      id
      name
      certification
      bio
      Category
      slug
      picture {
        localFile {
          publicURL
        }
      }
    }
  }
`;
